import { Box } from '@chakra-ui/react';
import { useTranslation } from 'next-i18next';
import ErrorPage from './ErrorPage';
import ButtonLink from '@/components/frontend/ButtonLink';
import Paragraph from '@/components/frontend/Paragraph';

const ListingNotFound = () => {
  const { t } = useTranslation('errors');

  return (
    <ErrorPage status={t('listing.not_found', 'Kohdesivua ei löydy')}>
      <Box textAlign="left" pt="5">
        <Paragraph>
          {t(
            'listing.no_more',
            'Hakemaasi kohdetta ei löytynyt tai se on poistunut myynnistä.',
          )}
        </Paragraph>
        <ButtonLink
          link={{
            title: t('listing.cta', 'Katso myynnissä olevat kohteet'),
            url: '/osta',
          }}
          colorScheme="primary"
        />
      </Box>
    </ErrorPage>
  );
};

export default ListingNotFound;

import { FC } from 'react';
import { Text, TextProps } from '@chakra-ui/react';

const Paragraph: FC<TextProps> = (props) => (
  <Text
    textStyle="lg"
    mb="1.5rem"
    whiteSpace="pre-line"
    sx={{ ':not(:last-child)': { mb: 5 } }}
    {...props}
  />
);

export default Paragraph;
